<script setup>
import SideMenu from '~/components/SideMenu.vue'
import Navbar from '~/components/Navbar.vue'
import { useAuthStore } from '~/store/auth.js'
import '~/assets/css/app-layout.scss'

const userStore = useAuthStore()
const user = computed(() => userStore.user)

function handleLogout() {
  userStore.logout()
}
</script>

<template>
  <div class="flex h-screen w-screen bg-gray-100">
    <SideMenu :user="user" />
    <main class="relative flex h-full flex-1 flex-col gap-6 overflow-auto">
      <Navbar :user="user" @logout="handleLogout" />
      <section class="px-2 pb-6 md:px-6">
        <slot />
      </section>
    </main>
  </div>
</template>
