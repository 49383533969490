<script setup>
import { Menu } from '@element-plus/icons-vue'
import { useNavigationStore } from '~/store/navigation.js'
import SentryFeedbackButtonHorizontal from './SentryFeedbackButtonHorizontal.vue'

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
})

const emits = defineEmits(['logout'])

const store = useNavigationStore()

function handleLogout() {
  emits('logout')
}
</script>

<template>
  <header class="bg-white px-6 py-3 shadow">
    <div class="flex items-center gap-6">
      <el-icon size="24" class="block text-primary lg:hidden" @click="store.openMobile()">
        <Menu />
      </el-icon>
      <div class="sentry-container hidden lg:block">
        <SentryFeedbackButtonHorizontal class="max-w-full whitespace-normal leading-5" :user="user" />
      </div>
      <div class="flex flex-1 items-center justify-end gap-6">
        <el-dropdown type="success" placement="bottom-end" popper-class="w-64" size="large">
          <template #default>
            <div class="flex items-center gap-3">
              <div class="hidden text-right md:block">
                <pre class="text-sm font-semibold">{{ user?.name }}</pre>
              </div>
              <el-avatar :icon="ElIconUserFilled" />
            </div>
          </template>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="ElIconUserFilled" @click="handleLogout">Logout</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </header>
</template>
