<script setup>
import { ref } from 'vue'
import axiosService from '~/services/axios.service.js'
import { ElMessage } from 'element-plus'

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  class: {
    type: String,
    required: false,
    default: '',
  },
})

const { $Sentry } = useNuxtApp()

const isModalVisible = ref(false)

const feedbackForm = ref()

const feedbackFormData = ref({
  feedback: '',
})

const isFeedbackSending = ref(false)

const feedbackFormRules = {
  feedback: [{ required: true, message: 'Please input your feedback', trigger: 'blur' }],
}

const handleClose = () => {
  isModalVisible.value = false
}

const handleSubmit = async () => {
  await feedbackForm.value.validate(async (valid) => {
    if (valid) {
      isFeedbackSending.value = true
      const replay = $Sentry?.getReplay()
      let projectId = null
      let replayUrl = 'https://avrillo-rd.sentry.io/replays/'
      let replayId = null

      if (replay) {
        await replay.flush()

        projectId = $Sentry?.getClient()?.getDsn()?.projectId
        replayId = replay?.getReplayId()
        replayUrl = `https://avrillo-rd.sentry.io/replays/${replayId}?project=${projectId}`
      }

      axiosService
        .post('/feedback', {
          feedback: feedbackFormData.value.feedback,
          employee_email: props.user.email,
          replay_url: replayUrl,
        })
        .then(() => {
          ElMessage.success('Feedback sent successfully')
        })
        .catch((err) => {
          ElMessage.error('Failed to send feedback')
        })
        .finally(() => {
          isModalVisible.value = false
          isFeedbackSending.value = false
          feedbackForm.value.resetFields()
        })
    }
  })
}
</script>

<template>
  <el-button type="default" class="ml-0 text-red-500" :class="props.class" size="large" @click="isModalVisible = true">
    To Employees: Leave your Feedback/Suggestions/Issues
  </el-button>
  <el-dialog v-model="isModalVisible" align-center title="Feedback/Issues" width="500" :before-close="handleClose">
    <el-form ref="feedbackForm" :model="feedbackFormData" :rules="feedbackFormRules">
      <el-form-item prop="feedback">
        <el-input v-model="feedbackFormData.feedback" type="textarea" rows="8" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="isModalVisible = false">Cancel</el-button>
        <el-button :disabled="isFeedbackSending" type="primary" @click="handleSubmit">Confirm</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped lang="postcss">
.el-form-item {
  @apply !mb-0;
}
</style>
